import React from "react";
import {useField, useFormikContext} from "formik";
import DatePicker, {registerLocale} from "react-datepicker";
import zhCN from 'date-fns/locale/zh-CN';
registerLocale('zhCN', zhCN)

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

function dateFormat(fmt, date) {
  let ret;
  const opt = {
    "Y+": date.getFullYear().toString(),        // 年
    "m+": (date.getMonth() + 1).toString(),     // 月
    "d+": date.getDate().toString(),            // 日
    "H+": date.getHours().toString(),           // 时
    "M+": date.getMinutes().toString(),         // 分
    "S+": date.getSeconds().toString()          // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
    };
  };
  return fmt;
}
export function DatePickerField({ ...props }) {
  const { setFieldValue, errors, touched } = useFormikContext();
  const {field} = (props);
  const onChange = props.onChange || (val => {
    console.log("val:", val);
    const format = props.showTimeSelect ? "YYYY-mm-dd HH:MM:SS" : "YYYY-mm-dd" ;
    const date = dateFormat(format, val);
    setFieldValue(field.name, date);
  });

  return (
    <>
      {props.label && <label>{props.label}</label>}
      <DatePicker
        locale="zhCN"
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        style={{ width: "100%" }}
        {...field}
        {...props}
        // selected={(new Date(field.value))}
        onChange={onChange}
      />
      {/*{errors[field.name] && touched[field.name] ? (*/}
      {/*  <div className="invalid-datepicker-feedback">*/}
      {/*    {errors[field.name].toString()}*/}
      {/*  </div>*/}
      {/*) : (<></>)}*/}
    </>
  );
}
