import React from "react";
import AssessmentDashboard from "../modules/Manage/_partials/AssessmentDashboard";
import {useSelector} from "react-redux";

export function DashboardPage() {
  return (<>
    {/*测评首页面板*/}
    <AssessmentDashboard />
  </>);
}
