
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .min(3, "不可小于3个字符")
      .max(50, "不可大于50字符")
      .required("请输入账号"),
    password: Yup.string()
      .min(3, "不可小于3个字符")
      .max(50, "不可大于50字符")
      .required("请输入密码"),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(values.email, values.password)
          .then(response => {
            // console.log(response);
            disableLoading();
            setSubmitting(false);
            if (response.code == 200)
            {
              let user = {username: response.data.username, truename: response.data.truename, role_name: response.data.role_name, role: response.data.role_code, functions: response.data.functions};
              props.login(response.data.token, user, response.data.current_street_name);
            }
            else
            {
              setStatus(response.msg);
            }
          })
          .catch((error) => {
            disableLoading();
            setSubmitting(false);
            setStatus(
              "服务器异常"
            );
          });
      }, 1000);
    },
  });

  const getBrowser = () => {
    var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
    var isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器
    var isIE = userAgent.indexOf("compatible") > -1
        && userAgent.indexOf("MSIE") > -1 && !isOpera; //判断是否IE浏览器
    var isEdge = userAgent.indexOf("Edge") > -1; //判断是否IE的Edge浏览器
    var isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器
    var isSafari = userAgent.indexOf("Safari") > -1
        && userAgent.indexOf("Chrome") == -1; //判断是否Safari浏览器
    var isChrome = userAgent.indexOf("Chrome") > -1
        && userAgent.indexOf("Safari") > -1; //判断Chrome浏览器

    if (isIE) {
      var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
      reIE.test(userAgent);
      var fIEVersion = parseFloat(RegExp["$1"]);
      if (fIEVersion == 7) {
        return "IE7";
      } else if (fIEVersion == 8) {
        return "IE8";
      } else if (fIEVersion == 9) {
        return "IE9";
      } else if (fIEVersion == 10) {
        return "IE10";
      } else if (fIEVersion == 11) {
        return "IE11";
      } else {
        return "0";
      }//IE版本过低
      return "IE";
    }
    if (isOpera) {
      return "Opera";
    }
    if (isEdge) {
      return "Edge";
    }
    if (isFF) {
      return "FF";
    }
    if (isSafari) {
      return "Safari";
    }
    if (isChrome) {
      return "Chrome";
    }

  }


  return (
    <>
      <style>
        {`
        .login.login-5 .login-signin,
        .login.login-5 .login-signup,
        .login.login-5 .login-forgot {
        display: none; }

        .login.login-5.login-signin-on .login-signup {
        display: none; }

        .login.login-5.login-signin-on .login-signin {
        display: block; }

        .login.login-5.login-signin-on .login-forgot {
        display: none; }

        .login.login-5.login-signup-on .login-signup {
        display: block; }

        .login.login-5.login-signup-on .login-signin {
        display: none; }

        .login.login-5.login-signup-on .login-forgot {
        display: none; }

        .login.login-5.login-forgot-on .login-signup {
        display: none; }

        .login.login-5.login-forgot-on .login-signin {
        display: none; }

        .login.login-5.login-forgot-on .login-forgot {
        display: block; }

        .login.login-5 .login-form {
        width: 100%;
        max-width: 450px; }

        @media (max-width: 575.98px) {
        .login.login-5 .login-form {
        width: 100%;
        max-width: 100%; } }
        `}
      </style>
      <div className="login-signin">
        <div className="mb-20">
          <h3 className="opacity-40 font-weight-normal">
            <img alt="logo" src="/media/logos/logo-login.png" width="180" />
          </h3>
          <div className="mt-20 mb-20">
            <h3 className="text-white opacity-40 font-weight-normal">拜博士后台管理系统</h3>
            <p className="text-white opacity-40">请输入您的账号和密码进行登录</p>
          </div>
        </div>
        <form className="form" id="kt_login_signin_form" onSubmit={formik.handleSubmit}>
          {formik.status ? (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible rounded-pill">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          ) : (<></>)}
          <div className="form-group">
            <input
              placeholder="账号"
              type="text"
              className={`form-control h-auto text-white bg-white-o-5 rounded-pill border-0 py-4 px-8 ${getInputClasses(
                "email"
              )}`}
              name="email"
              {...formik.getFieldProps("email")}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.email}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group">
            <input
              placeholder="密码"
              type="password"
              className={`form-control h-auto text-white bg-white-o-5 rounded-pill border-0 py-4 px-8 ${getInputClasses(
                "password"
              )}`}
              name="password"
              {...formik.getFieldProps("password")}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.password}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group text-center mt-10">
            <button
              id="kt_login_signin_submit"
              type="submit"
              disabled={formik.isSubmitting || getBrowser()!='Chrome'}
              className={`btn btn-pill btn-primary opacity-90 px-15 py-3`}
            >
              <span>{getBrowser()=='Chrome' ? '登录' : '请使用Chrome内核浏览器'}</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
