import React, {useEffect, useState} from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import axios from "axios";

export default function StreetDashboard() {
  const [baseData, setBaseData] = useState([]);

  useEffect(() => {
    axios.get(`/api/management/v1/month_ages/list`).then(response => {
      setBaseData(response.data);
    });
  }, []);

  return (<>
    <style>
      {
      `
      
      `}
    </style>
    <div className="row">
      {baseData.map((item, index) => (
        <div className="col-xl-3">
          {/*begin::Card*/}
          <div className="card card-custom gutter-b card-stretch">
            {/*begin::Body*/}
            <div className="card-body pt-4 d-flex flex-column justify-content-between">
              {/*begin::User*/}
              <div className="d-flex align-items-center mb-20">
                {/*begin::Pic*/}
                <div className="flex-shrink-0 mr-4 mt-lg-0 mt-3">
                  <div className="symbol symbol-lg-75 d-none">
                    <img alt="Pic" src="assets/media/users/300_10.jpg" />
                  </div>
                  <div className="symbol symbol-lg-75 symbol-primary">
                    <span className="symbol-label font-size-h3 font-weight-boldest">{item.month}</span>
                  </div>
                </div>
                {/*end::Pic*/}
                {/*begin::Title*/}
                <div className="d-flex flex-column">
                  <a href="#" className="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0">{item.title}</a>
                </div>
                {/*end::Title*/}
              </div>
              {/*end::User*/}
              <a href={`/manage/questions?month=${item.month}`} className="btn btn-block btn-sm btn-light-primary font-weight-bolder text-uppercase py-4">查看详情</a>
            </div>
            {/*end::Body*/}
          </div>
          {/*end::Card*/}
        </div>
      ))}
    </div>
  </>);
}
