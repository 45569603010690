import React from "react";
import {useField, useFormikContext} from "formik";
import {FieldFeedbackLabel} from "./FieldFeedbackLabel";
import Dropzone from 'react-dropzone';
import './FileDropzone.css';

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control", "form-control-solid"];
  if (touched && errors) {
    classes.push("is-invalid-select");
  }

  if (touched && !errors) {
    classes.push("is-valid-select");
  }

  return classes.join(" ");
};

export function FileDropzone({
   field, // { name, value, onChange, onBlur }
   form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
   label,
   withFeedbackLabel = true,
   customFeedbackLabel,
   type = "file",
   ...props
 }) {
  const { setFieldValue } = useFormikContext();
  const files = () => {
    if (typeof(field.value) == "string" ) return null;
    if (typeof (field.value) == "undefined") return null;
    return field.value.path;
  }

  return (
    <>
      {label && <label>{label}</label>}
      <Dropzone onDrop={acceptedFiles => {
        setFieldValue(field.name, acceptedFiles[0]);
      }}>
        {({getRootProps, getInputProps}) => (
          <section>
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
              <p>{files() ? files() : "请选择文件"}</p>
            </div>
          </section>
        )}
      </Dropzone>
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          label={label}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
